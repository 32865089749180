@import '../includes';

.mat-tab-group {
  &.mat-primary .mat-ink-bar {
    background-color: $primary-color;
  }

  .mat-tab-header {
    border-bottom: 1px solid $stock-tertiary;

    .mat-tab-list {
      margin: 0 10px;
    }
  }

  .mat-tab-label {
    height: 40px;
    padding: 0 8px;
    opacity: 1;
    min-width: unset;

    .mat-tab-label-content {
      @include font(13px, 20px, 500, $text-hint);
    }

    &-active {
      .mat-tab-label-content {
        @include font(13px, 20px, 700, $primary-color);
      }
    }
  }
}

.project-overflow {
  .mat-tab-group {
    height: calc(100vh - 310px);

    .mat-tab-body-content {
      overflow: inherit;
    }
  }

  &.inside-provider .mat-tab-group {
    height: 100% !important;
  }
}

.dashboard-box {
  .view-sourcing-invitation-section-content {
    .mat-tab-body {
      padding: 20px 20px 12px;
      width: calc(100vw - 370px);
      height: calc(100vh - 258px);

      &:first-child {
        padding: 0;
      }

      &:first-child .mat-tab-body-content {
        height: calc(100vh - 370px);
        overflow: auto;
        padding: 20px 20px 12px !important;
      }
    }
  }
}

.dashboard-box {
  .view-projects-tab {
    .mat-tab-body .mat-tab-body-content {
      height: calc(100vh - 306px);
      overflow: auto;
      padding: 20px !important;
    }
  }
}

.demand-other-tab,
.sourcing-invitation-container {
  .dashboard-box {
    .view-projects-tab {
      .mat-tab-body {
        padding: 0 !important;
        overflow-x: hidden;
      }
    }
  }
}

.demand-other-tab,
.sourcing-invitation-container {
  .dashboard-box {
    .view-projects-tab {
      .mat-tab-body {
        padding: 0 !important;
      }
    }
  }
}

.dashboard-box {
  .view-project-tab {
    &.project-mangement-invoicing {
      .mat-tab-body {
        height: 100% !important;
      }
    }
  }
}

.view-sourcing-invitation-section-content.sow-layout-step {
  .mat-tab-body-content {
    height: calc(100vh - 340px);
  }
}

.dashboard-box {
  &.sow-layout-step {
    .sow-tab-group {
      .mat-tab-body {
        padding: 0;

        &-content {
          height: calc(100vh - 233px);
        }
      }
    }
  }
}

.terms-condition-group {
  .sow-container {
    height: calc(100vh - 366px);
    overflow: auto;
  }
}

.dashboard-box {
  &.view-rfp-section-content {
    height: calc(100vh - 192px) !important;

    .mat-tab-body {
      &:nth-child(10) {
        padding: 0 !important;
      }
    }
  }
}

.performance-tab-group {
  .mat-tab-body {
    padding: 0;

    &-content {
      height: calc(100vh - 233px) !important;
    }
  }
}

.performance-questions-group {
  .mat-tab-body {
    padding: 0;

    &-content {
      height: unset !important;
    }
  }
}

.view-projects-tab {
  .mat-tab-body {
    overflow: auto;
    height: calc(100vh - 306px);
  }
}

.view-nda-tab {
  .mat-tab-body {
    // padding: 20px 0;
    overflow-y: auto !important;
    height: calc(100vh - 230px);

    &:first-child .mat-tab-body-content {
      height: calc(100vh - 418px);
      overflow-x: inherit;
    }
  }
}

.nda-container {
  .view-sourcing-invitation-section-content {
    .mat-tab-body-content {
      height: calc(100vh - 304px);
    }
  }
}

.view-msa-tab {
  .mat-tab-body {
    padding: 0;
    overflow-y: auto !important;
    height: calc(100vh - 258px);

    &:first-child .mat-tab-body-content {
      height: calc(100vh - 418px);
      overflow-x: inherit;
    }
  }
}

.sow-projects-tab {
  .mat-tab-body {
    padding: 20px 0;
    overflow-y: auto !important;
    height: calc(100vh - 434px);

    &:first-child .mat-tab-body-content {
      height: 100%;
      width: 100vw;
      overflow-x: hidden !important;
    }
  }
}

.sow-settings-tab {
  .mat-tab-body {
    padding: 20px 0;
    overflow-y: auto !important;
    height: calc(100vh - 300px);

    &:first-child .mat-tab-body-content {
      height: 100%;
    }
  }
}

.conpulse-tab-group {
  .mat-tab-group {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels .mat-tab-label {
          max-width: fit-content !important;
          min-width: fit-content;
          height: 40px;
          padding: 11px 8px !important;
          margin: 0 !important;
        }
      }
    }
  }
}

.rfp-tab-group {
  .mat-tab-group {
    .mat-tab-body {
      overflow-x: hidden;
      height: calc(100vh - 306px) !important;
    }

    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels .mat-tab-label {
          max-width: 100% !important;
          min-width: fit-content !important;
          padding: 11px 8px !important;
          margin: 0 !important;
        }
      }
    }

    .mat-ink-bar {
      max-width: 100% !important;
    }
  }
}

.rfp-chat-group {
  .mat-tab-group {
    .mat-tab-body {
      overflow-x: hidden;
      height: calc(100vh - 206px) !important;
    }

    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels .mat-tab-label {
          max-width: 100% !important;
          min-width: fit-content !important;
          padding: 11px 8px !important;
          margin: 0 !important;
        }
      }
    }

    .mat-ink-bar {
      max-width: 100% !important;
    }
  }
}

.impact-tab-group {
  .mat-tab-group {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels .mat-tab-label {
          max-width: fit-content !important;
          min-width: fit-content;
          padding: 11px 8px;
          height: 40px;
        }
      }
    }

    .mat-ink-bar {
      max-width: 100% !important;
    }
  }
}

.tab-group.mat-tab-group {
  .mat-tab-header {
    .mat-tab-label {
      min-width: unset;
      height: 40px !important;

      &.mat-tab-label-active {
        @include font(14px, 20px, 600, $primary-color !important);
        opacity: 1 !important;
      }
    }

    .mat-ink-bar {
      background-color: $primary-color !important;
    }
  }

  .mat-tab-body {
    padding: 16px 0;
  }
}

// .mat-tab-group {
//   &.mat-primary .mat-ink-bar {
//     background-color: $primary-color;
//   }
//   .mat-tab-header {
//     border-bottom: 1px solid $stock-tertiary;
//     .mat-tab-list {
//       margin: 0 10px;
//     }
//   }
//   .mat-tab-label {
//     height: 40px;
//     padding: 0 8px;
//     opacity: 1;
//     min-width: unset;

//     .mat-tab-label-content {
//       @include font(13px, 20px, 500, $text-hint);
//     }

//     &-active {
//       .mat-tab-label-content {
//         @include font(13px, 20px, 700, $primary-color);
//       }
//     }
//   }
// }

.nav-tab-group {
  .mat-tab-link-container {
    margin: 0 10px;
  }

  .mat-tab-link {
    min-width: unset;
    height: 40px !important;
    @include font(13px, 20px, 500, $text-hint);
    opacity: 1;
    padding: 11px 8px;

    &:hover {
      color: $text-hint;
      text-decoration: none;
    }

    &.mat-tab-label-active {
      @include font(13px, 20px, 700, $primary-color);
      opacity: 1 !important;
    }
  }

  .mat-ink-bar {
    background-color: $primary-color !important;
  }
}

.view-sourcing-invitation-section-content {
  .mat-tab-body-content {
    height: calc(100vh - 314px);
  }
}

.msa-tab-container {
  .mat-tab-body-content {
    height: 100% !important;
  }

  padding: 0;
}

.view-legal-entity-tab {
  .mat-tab-body-content {
    padding: 0;
    overflow: auto;
    height: calc(100vh - 181px);
  }
}

.view-client-profile-tab {
  .mat-tab-group {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels .mat-tab-label {
          max-width: 160px !important;
          min-width: 160px !important;
          height: 40px;
        }
      }
    }
  }

  .mat-tab-body {
    padding: 20px 0;
    overflow-y: auto !important;
    height: calc(100vh - 360px);

    &:first-child .mat-tab-body-content {
      height: 100%;
    }
  }
}

.entityscreen {
  padding: 0 !important;

  .mat-drawer-container {
    .mat-drawer-content {
      padding: 0 !important;
    }
  }

  .view-legal-entity-tab {
    .mat-tab-body-content {
      height: calc(100vh - 291px) !important;
    }
  }
}

.library-management-tab {
  .mat-tab-body {
    padding: 0 !important;
  }
}

.selection-criteria-group {
  .mat-tab-body {
    padding: 20px;
    height: calc(100vh - 306px);
  }
}

.selection-email-preview {
  .mat-tab-body {
    height: calc(100vh - 234px);
  }
}

.question-answer-container {
  .view-question-answer-section-content {
    height: calc(100vh - 194px) !important;
    overflow: auto !important;
  }
}

.sourcing-invitation-container {
  .view-sourcing-invitation-section-content {
    .associated-group {
      .reference-document {
        .sow-document {
          height: calc(100vh - 378px) !important;
        }
      }

      .mat-tab-body {
        .mat-tab-body-content {
          height: calc(100vh - 233px) !important;
        }
      }
    }
  }
}

.project-management-details-tab {
  .mat-tab-body {
    padding: 0;

    &-content {
      height: calc(100vh - 244px) !important;
    }
  }
}

.risk-tab {
  .mat-tab-group {
    height: calc(100vh - 310px);

    .mat-tab-body-content {
      height: calc(100vh - 364px);
    }
  }
}

.gird-container {

  .gird-holder {
    width: 320px;
    height: 320px;
    position: relative;

    .y-axis {
      // border: 1px solid;
      position: absolute;
      right: 92%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      align-items: start;

      .p-90 {
        height: 65px;
        transform: rotate(-90deg)
      }
    }

    .x-axis {
      justify-content: space-around;
    }
  }


  .mat-grid-list {
    width: 100%;
    height: 100%;

    mat-grid-tile {
      background: var(--grey-lighten-4);
      border-radius: 8px;
      font-weight: 600;
    }

    .tile-1,
    .tile-5,
    .tile-9 {
      background-color: var(--matrix-chart-orange);
    }

    .tile-2,
    .tile-6 {
      background-color: var(--matrix-chart-red-shad);
    }

    .tile-3 {
      background-color: var(--matrix-chart-red);
    }

    .tile-4,
    .tile-8 {
      background-color: var(--matrix-chart-green-shad);
    }

    .tile-7 {
      background-color: var(--matrix-chart-green);
    }


  }
}