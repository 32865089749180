@import '../includes';
/* Material Button style overwrite here */

button.mat-button,
a.mat-button {
  color: $secondary-color;
  transition: all 0.3s;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  padding: $spacer10;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.medium {
    padding: 8px $spacer10;
  }

  &.small {
    padding: 6px $spacer10;
  }

  &.mat-primary {
    color: $primary-color;

    &:hover {
      background-color: $primary-hint-4;
    }
  }

  &.mat-secondary {
    color: $secondary-color;

    &:hover {
      background-color: $secondary-hint-3;
    }
  }

  &.mat-calendar-period-button {
    color: $text-primary;
  }

  &[disabled] {
    color: $stock-primary !important;
    background-color: transparent !important;
    cursor: not-allowed;
  }
}

button.mat-flat-button,
a.mat-flat-button {
  background-color: $white-primary;
  border: none !important;
  color: $white-primary;
  transition: all 0.3s;
  border-radius: 6px;
  padding: $spacer10;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  border: none;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.medium {
    padding: 8px $spacer10;
  }

  &.small {
    padding: 6px $spacer10;
  }

  &.extra-small {
    padding: 4px $spacer10;
  }

  &.mat-primary {
    background: $primary-color;
    &:hover {
      background-color: $primary-light;
    }
  }

  &.mat-secondary {
    background-color: $secondary-color;
    &:hover {
      background-color: $secondary-light-color;
    }
  }

  &.mat-accent {
    background-color: $text-primary;
    &:hover {
      background-color: $text-tertiary;
    }
  }

  &.mat-warn {
    background-color: $error-primary;
    &:hover {
      background-color: $error-light;
    }
  }

  &[disabled] {
    background-color: $stock-primary !important;
    color: $white-primary !important;
    cursor: not-allowed;
  }
}

button.mat-stroked-button,
a.mat-stroked-button {
  border: 1px solid $text-primary;
  color: $text-primary;
  transition: all 0.3s;
  border-radius: 6px;
  padding: 9px $spacer10;
  font-weight: 600;
  letter-spacing: 0.28px;
  outline: none;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  &.medium {
    padding: 7px $spacer10;
  }

  &.small {
    padding: 5px $spacer10;
  }

  &.mat-primary {
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      background-color: $primary-hint-4;
    }
  }

  &.mat-secondary {
    border: 1px solid $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $secondary-hint-5;
    }
  }

  &.mat-accent {
    border: 1px solid $text-primary;
    color: $text-primary;

    &:hover {
      background-color: $stock-hint;
    }
  }

  &.mat-warn {
    border:1px solid $error-primary;
    color: $error-primary;

    &:hover {
      background-color: $error-hint;
    }
  }

  &[disabled] {
    background-color: $stock-primary !important;
    border-color: $stock-primary !important;
    color: $white-primary !important;
    cursor: not-allowed;
  }
}

button.icon-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: $text-secondary;
}

// Todo:: Added for button issue
.button-section {
  .text-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.delete-modal {
  .text-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.pdf-to-container,
.pdf-container{
      .mat-icon-button {
        @include border-radius(50%);
        @include size(32px);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          background-color: $background-primary;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .mat-button-wrapper{
          @include size(20px);
          display: flex;
        }
        .mat-icon {
          color: $text-light;
        }
      }
}

.action-icon {
  &.mat-icon-button {
    display: flex;
    align-items: center;

    .mat-button-wrapper{ 
      display: block;
      margin: auto;
    }
  }
}