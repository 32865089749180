@import '../includes';

/* Material Checkbox style overwrite here */
.mat-checkbox {
  .mat-checkbox-frame {
    border-color: $text-light;
  }

  .mat-checkbox-label {
    @include font(14px, 20px, 500, $text-secondary);
  }

  .mat-checkbox-layout {
    margin: 0;
  }

  &.mat-secondary.mat-checkbox-checked,
  &.mat-secondary.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $secondary-color;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $secondary-color !important;
}

//Todo todoCss: Need to move utilities file after an table scss removed from utilities
/* for tags*/
.tag {
  @include font(13px, 20px, 500, $text-secondary);
  padding: 4px 8px;
  @include border-radius(6px);
  min-width: 93px;
  display: inline-block;
  text-align: center;
  letter-spacing: normal;
  white-space: nowrap;
  &.tag-void,
  &.tag-pipeline-project,
  &.tag-on-going,
  &.tag-inprogress,
  &.tag-pending,
  &.tag-survey-sent {
    background-color: $warn-secondary;
    color: $warn-primary;
  }

  &.tag-won,
  &.tag-accepted,
  &.tag-closed,
  &.tag-paid,
  &.tag-pipeline-closed,
  &.tag-executed,
  &.tag-active,
  &.tag-approved,
  &.tag-completed,
  &.tag-pitch-invited,
  &.tag-pipeline-completed,
  &.tag-survey-completed {
    background-color: $success-tertiary;
    color: $success-primary;
  }

  &.tag-boarding-on-going,
  &.tag-open,
  &.tag-start,
  &.tag-pipeline-demand,
  &.tag-started,
  &.tag-sow-invited,
  &.tag-invited,
  &.tag-not-yet-sent,
  &.tag-draft {
    background-color: $secondary-hint-3;
    color: $secondary-color;
  }

  &.tag-uncollectible,
  &.tag-change-request,
  &.tag-inactive,
  &.tag-due,
  &.tag-requested,
  &.tag-rejected,
  &.tag-pipeline-rejected,
  &.tag-update-required,
  &.tag-changes-submitted,
  &.tag-stopped,
  &.tag-off-boarding {
    background-color: $error-hint;
    color: $error-primary;
  }

  &.tag-pipeline-idea {
    color: $lavender-tag;
    background: $lavender-tag-bg;
  }
}

//Todo todocss: need to move utilities file after an table scss removed from utilities
/* for breadcrumbs*/
.breadcrumbs {
  li {
    @include font(13px, 18px, 500, $text-hint);

    &:not(:last-child)::after {
      content: '/';
      padding: 0 6px;
    }

    .route-link:hover {
      @include font(13px, 18px, 500, $blue-color-fb);
      text-decoration: underline;
    }

    &.more-actions {
      display: flex;
      align-items: center;
      padding: 4px 6px;

      &:hover,
      &[aria-expanded='true'] {
        border-radius: 3px;
        background-color: $black-color-05;
      }
    }

    &:last-child {
      @include font(13px, 18px, 600, $text-primary);
    }
  }
}
