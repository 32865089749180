@import '../settings/variables';

/* Mat form field Textarea style overwrite here */
mat-form-field.mat-form-field-type-mat-input.textarea {
  .mat-form-field-infix {
    padding: 0;

    textarea {
      box-shadow: none;
      min-height: 60px;
      line-height: 1.4;
      padding: 0 $spacer8;
      margin: $spacer8 0;
      resize: none;

      &:focus {
        border: none;
      }
    }
  }
}

.performance-textarea {
  .mat-input-element {
    resize: none !important;
    height: 80px;
  }
}
