/* ==============================
   COLORS
---------------------------------*/
$secondary-color-04: var(--secondary-color-04);

$background-color: var(--background-color);


$text-primary-1: var(--text-grey-primary-1);
$text-primary-2: var(--text-grey-primary-2);

$text-grey-secondary: var(--text-grey-secondary);
$text-grey-secondary-50: vsr(--text-grey-secondary-50);

$text-grey-secondary-1: var(--text-grey-secondary-1);
$text-grey-secondary-1-08: var(--text-grey-secondary-1-08);

$text-grey-colors: var(--light-grey-colors);

$text-grey-hint: var(--text-grey-hint);
$text-grey-hint-20: var(--text-grey-hint-20);

$text-grey-content: var(--text-grey-content);
$text-grey-content-10: var(--text-grey-content-10);

$text-grey-line: var(--text-grey-line);
$text-grey-line-65: var(--text-grey-line-65);

$text-grey-progress: var(--text-grey-progress);

$text-grey-secondary-14: var(--grey-lighten-14);



$grey-darken-1: var(--grey-darken-1);
$grey-darken-2: var(--grey-darken-2);
$grey-darken-4: var(--grey-darken-4);
$grey-darken-5: var(--grey-darken-5);
$grey-darken-7: var(--grey-darken-7);
$grey-darken-8: var(--grey-darken-8);
$grey-lighten-2: var(--grey-lighten-2);
$grey-lighten-4: var(--grey-lighten-4);
$grey-lighten-12: var(--grey-lighten-12);
$grey-lighten-13: var(--grey-lighten-13);
$grey-lighten-15: var(--grey-lighten-15);

$grey-lighten-2-60: var(--grey-lighten-2-60);
$grey-lighten-2-50: var(--grey-lighten-2-50);
$grey-lighten-2-16: var(--grey-lighten-2-16);
$grey-lighten-2-05: var(--grey-lighten-2-05);
$grey-lighten-6-01: var(--grey-lighten-6-01);

$skeleton-loader: var(--skeleton-loader);
$dividerGrey: var(--dividerGrey);
$dividerPurple: var(--dividerPurple);

$red: var(--red);
$red-40: var(--red-40);
$red-20: var(--red-20);
$red-16: var(--red-16);
$red-15: var(--red-15);
$red-14: var(--red-14);
$red-10: var(--red-10);
$red-08: var(--red-08);
$red-05: var(--red-05);

$red-lighten1: var(--red-lighten1);
$red-lighten2: var(--red-lighten2);
$red-lighten3: var(--red-lighten3);
$red-lighten4: var(--error-red);
$red-lighten5: var(--red-lighten5);
$red-lighten6: var(--red-lighten6);

$green: var(--green);
$green-50: var(--green-50);
$green-40: var(--green-40);
$green-20: var(--green-20);
$green-16: var(--green-16);
$green-15: var(--green-15);
$green-10: var(--green-10);
$green-05: var(--green-05);
$green-04: var(--green-04);
$green-03: var(--green-03);

$green-lighten1: var(--green-lighten1);

$green-lighten2: var(--green-lighten2);
$green-lighten12: var(--light-green-12);
$green-lighten2-40: var(--green-lighten2-40);
$green-lighten-bg: var(--green-lighten-bg);
$green-lighten4: var(--green-lighten4);

$green-lighten3: var(--green-lighten3);
$green-dark: var(--green-dark);

$blue: var(--blue);
$blue-40: var(--blue-40);
$blue-20: var(--blue-20);
$blue-16: var(--blue-16);
$blue-15: var(--blue-15);
$blue-05: var(--blue-05);
$blue-01: var(--blue-01);
$blue-12: var(--blue-12);

$blue-lighten1: var(--blue-lighten1);
$blue-lighten2: var(--blue-lighten2);
$blue-lighten3: var(--blue-lighten3);

$warn-color: var(--warn-color);
$warn-color-50: var(--warn-color-50);

$warning-color-dark: var(--warning-color-dark);

$pink-color: var(--pink-color);

$yellow: var(--yellow);
$yellow-20: var(--yellow-20);

$pink-legend-color: var(--pink-legend-color);

$dark-green: var(--dark-green);
$dark-green-80: var(--dark-green-80);
$dark-green-20: var(--dark-green-20);
$dark-green-16: var(--dark-green-16);
$dark-green-14: var(--dark-green-14);
$dark-green-12: var(--dark-green-12);
$dark-green-10: var(--dark-green-10);

$dark-green-lighten: var(--dark-green-lighten);

$orange-color: var(--orange-color);
$orange-color-40: var(--orange-color-40);
$orange-color-20: var(--orange-color-20);
$orange-color-16: var(--orange-color-16);
$orange-color-15: var(--orange-color-15);
$orange-color-14: var(--orange-color-14);
$orange-color-10: var(--orange-color-10);
$orange-color-05: var(--orange-color-05);

$light-green: var(--light-green);

$peak-green: var(--peak-green);
$peak-green-15: var(--peak-green-15);
$peak-green-10: var(--peak-green-10);
$peak-green-08: var(--peak-green-08);
$peak-green-05: var(--peak-green-05);

$dark-pink: var(--dark-pink);
$dark-pink-15: var(--dark-pink-15);
$dark-pink-05: var(--dark-pink-05);

$dark-yellow: var(--dark-yellow);
$dark-yellow-15: var(--dark-yellow-15);
$dark-yellow-05: var(--dark-yellow-05);

$dark-red: var(--dark-red);
$dark-red-05: var(--dark-red-05);

$royal-blue: var(--royal-blue);
$royal-blue-05: var(--royal-blue-05);

$dark-blue: var(--dark-blue);
$dark-blue-05: var(--dark-blue-05);

$darken-blue: var(--darken-blue);

/*===============================
  Social Colors
---------------------------------*/

$fb-color: var(--fb-color);
$tw-color: var(--tw-color);
$gplus-color: var(--gplus-color);
$yt-color: var(--yt-color);
$li-color: var(--li-color);
$pin-color: var(--pin-color);
$ins-color: var(--ins-color);
$git-color: var(--git-color);
$comm-color: var(--comm-color);
$vk-color: var(--vk-color);
$drib-color: var(--drib-color);
$note-bg-color: var(--note-bg-yellow);

/*===============================
  Conplus Brand Colors
---------------------------------*/

// New Colors Oct 2023

// Hex
$granite-gray: #6c6861;
$white-02: #eef3f7;
$white-03: #f3f6fa;
$primary-color-09: #fcefc0;
$notice-color-1: #f6cc001a;

// Rgba
$fulvous-opacity-12: rgba(232, 139, 0, 0.12);
$grey-opacity-43: rgba(127, 127, 127, 0.43);
$blue-lighten7: rgba(0, 171, 187, 0.12);
$blue-lighten8: rgba(0, 171, 187, 1);
$light-green-12: rgba(0, 175, 100, 1);
$light-green-13: rgba(0, 175, 100, 0.12);

/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
$primary-color: var(--primary-color);
$primary-color-03: var(--primary-color-03);
$primary-color-05: var(--primary-color-05);
$primary-color-06: var(--primary-color-06);
$primary-color-10: var(--primary-color-10);
$primary-color-12: var(--primary-color-12);
$primary-color-15: var(--primary-color-15);
$primary-color-16: var(--primary-color-16);
$primary-color-20: var(--primary-color-20);
$primary-color-40: var(--primary-color-40);
$primary-color-70: var(--primary-color-70);
$primary-light: var(--primary-light);
$primary-hint-3: var(--primary-hint-3);
$primary-hint-4: var(--primary-hint-4);

/* ==============================
 Secondary colors
---------------------------------*/
$secondary-color: var(--secondary-color);
$secondary-color-1: var(--secondary-color-1);
$secondary-dark-color: var(--secondary-dark-color);
$secondary-darker-color: var(--secondary-darker-color);
$secondary-light-color: var(--secondary-light-color);
$secondary-hint-2: var(--secondary-hint-2);
$secondary-hint-3: var(--secondary-hint-3);
$secondary-hint-4: var(--secondary-hint-4);
$secondary-hint-5: var(--secondary-hint-5);

/* ==============================
 Text colors
---------------------------------*/

$text-primary: var(--text-primary);
$text-secondary: var(--text-secondary);
$text-tertiary: var(--text-tertiary);
$text-light: var(--text-light);
$text-hint: var(--text-hint);
$text-warn: var(--text-warn);
$text-success: var(--text-success);
$text-error: var(--text-error);
$text-primary-3: var(--text-grey-primary-3);
$text-primary-4: var(--text-grey-primary-4);

/* ==============================
 Background colors
---------------------------------*/
$background-darker: var(--background-darker);
$background-dark: var(--background-dark);
$background-primary: var(--background-primary);

/* ==============================
  Stock colors
---------------------------------*/
$stock-primary: var(--stock-primary);
$stock-secondary: var(--stock-secondary);
$stock-tertiary: var(--stock-tertiary);
$stock-hint: var(--stock-hint);

/* ==============================
  Error colors
---------------------------------*/
$error-primary: var(--error-primary);
$error-secondary: var(--error-secondary);
$error-secondary-01: var(--error-secondary-01);
$error-tertiary: var(--error-tertiary);
$error-dark: var(--error-dark);
$error-hint: var(--error-hint);
$error-primary-01: var(--error-primary-01);
$error-light: var(--error-light);

/* ==============================
  Success colors
---------------------------------*/
$success-primary: var(--success-primary);
$success-secondary: var(--success-secondary);
$success-tertiary: var(--success-tertiary);
$success-dark: var(--success-dark);
$success-primary-1: var(--success-primary-1);
$success-dark-1: var(--success-dark-1);
$success-dark-2: var(--success-dark-2);
$success-lighten: var(--success-lighten);

/* ==============================
  Warn colors
---------------------------------*/
$warn-primary: var(--warn-primary);
$warn-secondary: var(--warn-secondary);
$warn-tertiary: var(--warn-tertiary);
$warning-color-lighten: var(--warning-color-lighten);
$warning-color-lighten-1: var(--warning-color-lighten-1);
$warn-secondary-2: var(--warn-secondary-2);

/* ==============================
  Other colors
---------------------------------*/
// White
$white-primary: var(--white-primary);
$white-color-76: var(--white-color-76);
$white-color-73: var(--white-color-73);
$white-color-60: var(--white-color-60);
$white-color-56: var(--white-color-56);
$white-color-20: var(--white-color-20);
$white-color-21: var(--white-color-21);
$white-color-17: var(--white-color-17);
$bg-light-white: var(--bg-light-white);
$white-lilac: var(--white-lilac);
$white-lavender: var(--white-lavender);
$white-hint-1: var(--white-hint-1);
$background-light-blue: var(--background-light-blue);

// Black
$black-primary: var(--black-primary);
$black-color-90: var(--black-color-90);
$black-color-87: var(--black-color-87);
$black-color-85: var(--black-color-85);
$black-color-80: var(--black-color-80);
$black-color-45: var(--black-color-45);
$black-color-40: var(--black-color-40);
$black-color-36: var(--black-color-36);
$black-color-30: var(--black-color-30);
$black-color-24: var(--black-color-24);
$black-color-21: var(--black-color-21);
$black-color-20: var(--black-color-20);
$black-color-16: var(--black-color-16);
$black-color-14: var(--black-color-14);
$black-color-15: var(--black-color-15);
$black-color-12: var(--black-color-12);
$black-color-60: var(--black-color-60);
$black-color-65: var(--black-color-65);
$black-color-10: var(--black-color-10);
$black-color-07: var(--black-color-07);
$black-color-06: var(--black-color-06);
$black-color-05: var(--black-color-05);
$black-color-03: var(--black-color-03);
$black-color-54: var(--black-color-54);
$black-opacity-10: var(--black-opacity-10);

// Grey
$grey-color-51: var(--grey-color-51);
$grey-lighten-1: var(--grey-lighten-1);
$grey-lighten-3: var(--grey-lighten-3);
$grey-lighten-5: var(--grey-lighten-5);
$grey-lighten-6: var(--grey-lighten-6);
$grey-lighten-7: var(--grey-lighten-7);
$grey-lighten-9: var(--grey-lighten-9);
$grey-lighten-11: var(--grey-lighten-11);
$grey-lighten-14: var(--grey-lighten-14);
$grey-lighten-15: var(--grey-lighten-15);
$grey-lighten-16: var(--grey-lighten-16);
$grey-lighten-17: var(--grey-lighten-17);
$grey-lighten-18: var(--grey-lighten-18);
$grey-lighten-19: var(--grey-lighten-19);
$grey-darken-3: var(--grey-darken-3);
$grey-darken-6: var(--grey-darken-6);
$grey-darken-9: var(--grey-darken-9);
$grey-darken-10: var(--grey-darken-10);
$grey-darken-11: var(--grey-darken-11);
$grey-darken-12: var(--grey-darken-12);
$grey-darken-13: var(--grey-darken-13);
$border-grey: var(--border-grey);
$grey-hint-20: var(--grey-hint-20);
$grey-hint-10: var(grey-hint-10);

// Blue
$blue-lighten5: var(--blue-lighten5);
$blue-lighten10: var(--blue-lighten-10);
$blue-color-fb: var(--blue-color-fb);
$blue-darken-1: var(--blue-darken-1);
$blue-darken-2: var(--blue-darken-2);
$blue-darken-3: var(--blue-darken-3);
$blue-darken-4: var(--blue-darken-4);
$blue-lighten-2: var(--blue-lighten-2);

// Green
$dark-green-63: var(--dark-green-63);
$dark-green-631f: var(--dark-green-631f);
$green-darken-1: var(--green-darken-1);
$green-lighten-bg: var(--green-lighten-bg);

// Red
$red-lighten-bg: var(--red-lighten-bg);

// Yellow
$yellow-lighten-1: var(--yellow-lighten-1);

//Orange
$orange-lighten-01: var(--orange-lighten-01);

//Notes
$bg-note-color: var(--bg-note-color);
$notice-color: var(--notice-color);
$notice-bg-color: var(--notice-bg-color);
$note-border-color: var(--note-border-color);
$note-bg-color-1: var(--note-bg-color-1);

// Tag
$lavender-tag: var(--lavender-tag);
$lavender-tag-bg: var(--lavender-tag-bg);