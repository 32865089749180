/* ==============================
   CHECKOUT SPECIFIC COLORS
---------------------------------*/

:root {
  /* ==============================
   OLD COLORS
   TODO:- Check the usage and remove
---------------------------------*/
  --secondary-color-04: rgba(103, 0, 51, 0.04);

  --background-color: #f9f2f5;
  --background-light-blue: #f2f6f9;
  --bg-light-white: #F7FAFC;
  --bg-reddish-orange: #fff1e6;

  --text-grey-primary-1: #03011e;
  --text-grey-primary-2: #434850;


  --text-grey-secondary: rgb(66, 66, 66);
  --text-grey-secondary-50: rgba(66, 66, 66, 0.5);

  --text-grey-secondary-1: rgb(74, 74, 74);
  --text-grey-secondary-1-08: rgba(74, 74, 74, 0.08);

  --text-grey-hint: rgb(155, 155, 155);
  --text-grey-hint-20: rgba(155, 155, 155, 0.2);

  --text-grey-content: rgb(127, 127, 127);
  --text-grey-content-10: rgba(127, 127, 127, 0.1);

  --text-grey-line: rgb(187, 186, 186);
  --text-grey-line-65: rgba(187, 186, 186, 0.65);

  --text-grey-progress: #838383;

  --grey-darken-1: #333333;
  --grey-darken-2: #666666;
  --grey-darken-4: #18162d;
  --grey-darken-5: #22293d;
  --grey-darken-7: #e1e1e163;
  --grey-darken-8: #06172c;
  --grey-lighten-2: #e2e2e2;
  --grey-lighten-4: #e9ebee;
  --grey-lighten-8: #e5e5e5;
  --grey-lighten-11: #d9d9d9;
  --grey-lighten-12: #b9babd;
  --grey-lighten-13: #5b5f66;

  --grey-lighten-2-60: rgba(226, 226, 226, 0.6);
  --grey-lighten-2-50: rgba(226, 226, 226, 0.5);
  --grey-lighten-2-16: rgba(226, 226, 226, 0.16);
  --grey-lighten-2-05: rgba(226, 226, 226, 0.05);
  --grey-lighten-6-01: rgba(215, 215, 215, 0.01);

  --skeleton-loader: #f6f7f9;
  --dividerGrey: rgba(0, 0, 0, 0.3);
  --black-opacity-10: rgba(0, 0, 0, 0.1);
  --dividerPurple: rgba(100, 28, 255, 0.19);

  --red: rgb(224, 0, 52);
  --red-40: rgba(224, 0, 52, 0.4);
  --red-20: rgba(224, 0, 52, 0.2);
  --red-16: rgba(224, 0, 52, 0.16);
  --red-15: rgba(224, 0, 52, 0.15);
  --red-14: rgba(224, 0, 52, 0.14);
  --red-10: rgba(224, 0, 52, 0.1);
  --red-08: rgba(224, 0, 52, 0.08);
  --red-05: rgba(224, 0, 52, 0.05);

  --red-lighten1: rgb(255, 166, 160);
  --red-lighten2: rgb(255, 240, 240);
  --red-lighten3: rgba(224, 0, 52, 0.08);
  --red-lighten5: rgba(224, 0, 52, 0.2);
  --red-lighten6: rgba(255,119,0,0.2);
  --green-lighten-bg: rgba(224, 245, 236, 1);

  --green: rgb(0, 173, 98);
  --green-50: rgba(0, 173, 98, 0.5);
  --green-40: rgba(0, 173, 98, 0.4);
  --green-20: rgba(0, 175, 98, 0.2);
  --green-16: rgba(0, 173, 98, 0.16);
  --green-15: rgba(0, 173, 98, 0.15);
  --green-10: rgba(0, 173, 98, 0.1);
  --green-05: rgba(0, 173, 98, 0.05);
  --green-04: rgba(0, 175, 98, 0.04);
  --green-03: rgba(0, 175, 98, 1);

  --green-lighten1: #7ed321;
  --green-lighten4:rgba(0, 175, 99, 0.2);

  --green-lighten2: rgb(188, 238, 185);
  --green-lighten2-40: rgba(188, 238, 185, 0.4);

  --green-lighten3: rgba(0, 175, 99, 0.15);
  --green-dark: rgba(0, 175, 99, 100);

  --blue: rgb(10, 161, 251);
  --blue-40: rgba(10, 161, 251, 0.4);
  --blue-20: rgba(10, 161, 251, 0.2);
  --blue-16: rgba(10, 161, 251, 0.16);
  --blue-15: rgba(10, 161, 251, 0.15);
  --blue-05: rgba(10, 161, 251, 0.05);
  --blue-12: rgba(10, 161, 251, 0.12);
  --blue-01: rgba(10, 161, 251, 1);

  --blue-lighten1: #9cddfa;
  --blue-lighten2: #e3f2fd;
  --blue-lighten3: rgba(255, 119, 0, 0.15);
  --blue-bg-light: #F0FAFB;

  --warn-color: rgb(252, 87, 94);
  --warn-color-50: rgba(252, 87, 94, 0.5);

  --warning-color-dark: #fc854a;

  --pink-color: #fd73a1;

  --yellow: rgb(255, 194, 51);
  --yellow-20: rgba(255, 194, 51, 0.2);

  --pink-legend-color: rgba(224, 0, 52, 0.2);

  --dark-green: rgb(0, 171, 187);
  --dark-green-80: rgb(0, 171, 187, 0.8);
  --dark-green-20: rgb(0, 171, 187, 0.2);
  --dark-green-16: rgb(0, 171, 187, 0.16);
  --dark-green-14: rgb(0, 171, 187, 0.14);
  --dark-green-12: rgb(0, 171, 187, 0.12);
  --dark-green-10: rgb(0, 171, 187, 0.1);

  --dark-green-lighten: rgba(0, 171, 187, 0.06);

  --orange-color: rgb(255, 119, 0);
  --orange-color-40: rgba(255, 119, 0, 0.4);
  --orange-color-20: rgba(255, 119, 0, 0.2);
  --orange-color-16: rgba(255, 119, 0, 0.16);
  --orange-color-15: rgba(255, 119, 0, 0.15);
  --orange-color-14: rgba(255, 119, 0, 0.14);
  --orange-color-10: rgba(255, 119, 0, 0.1);
  --orange-color-05: rgba(255, 119, 0, 0.05);

  --light-green: #4cd964;
  --light-green-12: rgba(0, 175, 99, 0.12);
  --peak-green: rgb(0, 127, 139);
  --peak-green-15: rgba(0, 127, 139, 0.15);
  --peak-green-10: rgba(0, 127, 139, 0.1);
  --peak-green-08: rgba(0, 127, 139, 0.08);
  --peak-green-05: rgba(0, 127, 139, 0.05);

  --dark-pink: rgb(189, 16, 224);
  --dark-pink-15: rgba(189, 16, 224, 0.15);
  --dark-pink-05: rgba(189, 16, 224, 0.05);

  --dark-yellow: rgb(255, 188, 0);
  --dark-yellow-15: rgba(255, 188, 0, 0.15);
  --dark-yellow-05: rgba(255, 188, 0, 0.05);

  --dark-red: rgb(208, 2, 27);
  --dark-red-05: rgba(208, 2, 27, 0.05);

  --royal-blue: rgb(0, 19, 255);
  --royal-blue-05: rgba(0, 19, 255, 0.05);

  --dark-blue: rgb(0, 82, 130);
  --dark-blue-05: rgba(0, 82, 130, 0.05);

  --darken-blue: #0066a1;

  --border-grey: #e3e3e3;

  --light-grey-colors: #aaa;
  /*===============================
    Social Colors
  ----------------------------------*/

  --fb-color: #3b5998;
  --tw-color: #55acee;
  --gplus-color: #dd4b39;
  --yt-color: #cd201f;
  --li-color: #0082ca;
  --pin-color: #c61118;
  --ins-color: #3f729b;
  --git-color: #4183c4;
  --comm-color: #30cfc0; 
  --vk-color: #4c75a3;
  --drib-color: #c32361;
  
  --error-red: rgba(224, 0, 52, 0.12);
  --note-bg-yellow: #fffae5;

  --matrix-chart-orange: #ff9233;
  --matrix-chart-red: #fa003a;
  --matrix-chart-red-shad: #ff551ff0;
  --matrix-chart-green: #007242;
  --matrix-chart-green-shad: #838e17d6;
 

/* ==============================
  NEW DESIGN v2 COLORS
  Primary
---------------------------------*/
  --primary-color: #FF7700;
  // The below are opacity variations -03 = 0.03 opacity
  --primary-color-03: #FF770008;
  --primary-color-05: #FF77000D;
  --primary-color-06: #FF77000F;
  --primary-color-10: #FF77001A;
  --primary-color-12: #FF77001F;
  --primary-color-15: #FF770026;
  --primary-color-16: #FF770029;
  --primary-color-20: #FF770033;
  --primary-color-40: #FF770066;
  --primary-color-70: #FF7700B3;
  --primary-light: #FF9233;
  --primary-hint-3: #FFF1E6;
  --primary-hint-4: #fff7f0;

/* ==============================
  NEW DESIGN v2 COLORS
  Secondary
---------------------------------*/
--secondary-color: #00ABBB;
--secondary-color-1: #00abbb1a; 
--secondary-dark-color: #008996;
--secondary-darker-color: #006770;
--secondary-light-color: #2EBBC9;
--secondary-hint-2: #CDF5F8;
--secondary-hint-3: #ECF9FA;
--secondary-hint-4: #F8FCFD;
--secondary-hint-5: #f0fafb;



/* ==============================
  NEW DESIGN v2 COLORS
  Text colors
---------------------------------*/
--text-primary: #0C121D;
--text-secondary: #3D414A;
--text-tertiary: #61656C;
--text-warn: #E88B00;
--text-light: #AAACB0;
--text-hint: #85888E;
--text-success: #00AF62;
--text-error: #E00034;
--text-grey-primary-3: #11161f;
--text-grey-primary-4: #424242;

/* ==============================
  NEW DESIGN v2 COLORS
  Background colors
---------------------------------*/
--background-darker: #D5DDE2;
--background-dark: #E6EBEE;
--background-primary: #F2F7FA;

/* ==============================
  NEW DESIGN v2 COLORS
  Stock colors
---------------------------------*/
--stock-primary: #C2C4C6;
--stock-secondary: #CED0D2;
--stock-tertiary: #DBDBDD;
--stock-hint: #E7E7E8;

/* ==============================
  NEW DESIGN v2 COLORS
  Error colors
---------------------------------*/
--error-primary: #E00034;
--error-secondary: #FF003B;
--error-tertiary: #FBE0E7;
--error-dark: #C7002E;
--error-hint: #FDF0F3;
--error-primary-01: #E000341F;
--error-light: #FA003A;
--error-secondary-01: #ff1a0030;
/* ==============================
  NEW DESIGN v2 COLORS
  Success colors
---------------------------------*/
--success-primary: #00AF62;
--success-secondary: #E0F5EC;
--success-tertiary: #F0FAF6;
--success-dark: #008F52;
--success-primary-1: #00c851;
--success-dark-1: #00af621f;
--success-dark-2: #00af621a;
--success-lighten: #8bf18ba6;


/* ==============================
  NEW DESIGN v2 COLORS
  Warn colors
---------------------------------*/
--warn-primary: #E88B00;
--warn-secondary: #FFFAE5;
--warn-tertiary: #BD7100;
--warning-color-lighten: #ffcc00;
--warning-color-lighten-1: #ffcc0033;
--warn-secondary-2: #fff2d9;


/* ==============================
  NEW DESIGN v2 COLORS
  Other colors
---------------------------------*/
// White
--white-primary: #FFFFFF;
--white-color-76: rgba(255, 255, 255, 0.76);
--white-color-73: rgba(255, 255, 255, 0.73);
--white-color-60: rgba(255, 255, 255, 0.6);
--white-color-56: rgba(255, 255, 255, 0.56);
--white-color-21: rgba(255, 255, 255, 0.21);
--white-color-20: rgba(255, 255, 255, 0.2);
--white-color-17: rgba(255, 255, 255, 0.17);
--white-lilac: #f6f6f6;
--white-lavender: #e8e8e9;
--white-hint-1: #D3D6DB;

// Black
--black-primary: #000000;
--black-color-90: rgba(0, 0, 0, 0.9);
--black-color-87: rgba(0, 0, 0, 0.87);
--black-color-85: rgba(0, 0, 0, 0.85);
--black-color-80: rgba(0, 0, 0, 0.8);
--black-color-45: rgba(0, 0, 0, 0.45);
--black-color-40: rgba(0, 0, 0, 0.4);
--black-color-36: rgba(0, 0, 0, 0.36);
--black-color-30: rgba(0, 0, 0, 0.3);
--black-color-24: rgba(0, 0, 0, 0.24);
--black-color-21: rgba(0, 0, 0, 0.21);
--black-color-20: rgba(0, 0, 0, 0.2);
--black-color-16: rgba(0, 0, 0, 0.16);
--black-color-14: rgba(0, 0, 0, 0.14);
--black-color-15: rgba(0, 0, 0, 0.15);
--black-color-12: rgba(0, 0, 0, 0.12);
--black-color-10: rgba(0, 0, 0, 0.1);
--black-color-60: rgba(0, 0, 0, 0.6);
--black-color-65: rgba(0, 0, 0, 0.65);
--black-color-07: rgba(0, 0, 0, 0.07);
--black-color-06: rgba(0, 0, 0, 0.06);
--black-color-05: rgba(0, 0, 0, 0.05);
--black-color-03: rgba(0, 0, 0, 0.03);
--black-color-54: rgba(0, 0, 0, 0.54);

// Grey
--grey-color-51: rgba(225, 225, 225, 0.51);
--grey-lighten-1: #cccccc;
--grey-lighten-3: #f7f7f7;
--grey-lighten-5: #f0f0f0;
--grey-lighten-7: #7f7f7f;
--grey-lighten-6: #d7d7d7;
--grey-lighten-9: #8a8d92;
--grey-lighten-14: #f9f9f9;
--grey-lighten-15: #fafafa;
--grey-lighten-16: #cdd1d5;
--grey-lighten-17: #f8f8f8;
--grey-lighten-18: #d8d8d8;
--grey-lighten-19: #eeeff0;
--grey-darken-3: #999999;
--grey-darken-6: #e1e1e1;
--grey-darken-9: #a1a3a7;
--grey-darken-10: #595959;
--grey-darken-11: #72767c;
--grey-darken-12: #cac9c9bf;
--grey-darken-13: #cccccc4d;
--grey-hint-20: #9b9b9b4d;
--grey-hint-10: #9b9b9b1a;

// Blue
--blue-lighten5: #007883;
--blue-lighten-10: #00abbb0f;
--blue-color-fb: #0aa1fb;
--blue-darken-1: #00457f1a;
--blue-darken-2: #0990e0;
--blue-darken-3: #11161fb3;
--blue-darken-4: #11161fb3;
--blue-lighten-2: #e0f5f7;

// Green 
--dark-green-63: #00af63;
--dark-green-631f: #00af631f;
--green-darken-1: #25b09b;
--green-lighten-bg: #eaf8f3;

// Red
--red-lighten-bg: #fbf0f3;

//Yellow
--yellow-lighten-1: #ebd997;

//Orange
--orange-lighten-01: #cf2c170d;



// Notes
--bg-note-color: #FEFBE7;
--notice-bg-color: #ffcc001a;
--notice-color: #f6be2c33;
--note-border-color: #fceec1;
--note-bg-color-1: #fefae6;

// Tag
--lavender-tag: #8D73FF;
--lavender-tag-bg: #A894FF1F;


}

.client-app {
  --primary-color: #FF7700;
  // The below are opacity variations -03 = 0.03 opacity
  --primary-color-03: #FF770008;
  --primary-color-05: #FF77000D;
  --primary-color-06: #FF77000F;
  --primary-color-10: #FF77001A;
  --primary-color-12: #FF77001F;
  --primary-color-15: #FF770026;
  --primary-color-16: #FF770029;
  --primary-color-20: #FF770033;
  --primary-color-40: #FF770066;
  --primary-color-70: #FF7700B3;
  --primary-light: #FF9233;
}
