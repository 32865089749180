@import '../includes';

/* Mat form field style overwrite here */
mat-form-field.mat-form-field {
  font-size: 14px;
  margin-bottom: $spacer16;
  position: relative;
  font-family: 'Open Sans', sans-serif;

  &.full-width {
    width: 100%;
  }

  .mat-form-field-label {
    @include font(14px, 38px, 500, $text-light);
  }

  &.mat-focused .mat-form-field-label {
    color: $text-light;
  }

  .mat-form-field-flex {
    align-items: center;

    .mat-form-field-infix {
      width: 100%;
      border: 0;
      padding: 0;
    }

    .mat-form-field-label {
      line-height: 38px;
      padding-left: 10px;
      top: 0.84375em;

      .mat-placeholder-required {
        display: none;
      }
    }

    .mat-input-element {
      padding: 9px 10px;
      margin-top: 0;
      @include font(14px, 20px, 600, $text-primary);
    }

    .mat-form-field-prefix .mat-icon,
    .mat-form-field-suffix .mat-icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
      color: $text-hint;
      align-items: center;
      display: flex;
    }

    .mat-form-field-prefix .mat-icon {
      margin-left: 10px;
    }

    .mat-form-field-suffix .mat-icon {
      margin-right: 10px;
    }

    .mat-form-field-prefix + .mat-form-field-infix {
      padding-left: 0;

      .mat-form-field-label {
        padding-left: 10px;
      }
    }
  }

  &.no-border-input {
    margin: 0;

      &.options {
        margin-bottom: 10px;
      }

      .mat-form-field-flex {
        border: none;

        .mat-input-element {
          padding: 0;
          @include font(14px, 20px, 400, $text-tertiary);
        }
      }
  }

  &.medium {
    .mat-form-field-flex {
      .mat-form-field-label {
        line-height: 34px;
      }
      .mat-input-element {
        padding: 7px 10px;
      }
    }
  }

  //mat form field underline style overwrite here
  .mat-form-field-underline {
    bottom: 0;
    opacity: 0 !important;
    z-index: -1;
  }

  .mat-floating-placeholder {
    opacity: 0 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  // &.single-border {
  //   .mat-input-element {
  //     padding: $spacer10 $spacer8 $spacer10 0;
  //   }

  //   &.input-medium {
  //     .mat-input-element {
  //       padding: $spacer8 $spacer8 $spacer8 0;
  //     }
  //   }

  //   &.mat-form-field .mat-form-field-label {
  //     padding-left: 0;
  //   }

  //   &.dynamic-input.mat-form-field .mat-form-field-label {
  //     padding-left: 14px;
  //   }

  //   &:focus,
  //   &.mat-focused {
  //     .mat-form-field-flex {
  //       border-bottom: 1px solid $primary-color!important;
  //       box-shadow: none !important;
  //     }

  //     &.mat-form-field-invalid .mat-form-field-flex {
  //       background-color: transparent;
  //       box-shadow: none;
  //     }
  //   }
  // }

  // &.no-border {
  //   .mat-form-field-prefix {
  //     padding-left: 0;
  //   }

  //   .mat-form-field-flex {
  //     border: none !important;
  //     box-shadow: none !important;
  //   }
  // }

  &-disabled .mat-form-field-flex {
    border-color: $background-primary !important;
    background-color: $background-primary !important;
    border-radius: 6px;

    .mat-input-element {
      cursor: not-allowed;
    }

    .mat-form-field-label {
      color: $text-light;
    }
  }

  &-invalid .mat-form-field-flex {
    border: 1px solid $error-primary !important;
    background-color: $error-hint !important;

    .mat-form-field-label {
      color: $text-light;
    }
    // &::after {
    //   color: $error-primary !important;
    //   content: 'info' !important;
    //   font-family: 'Material Icons';
    //   font-feature-settings: 'liga';
    //   font-size: 150%;
    //   padding-right: 8px;
    // }
  }

  //Textarea Placeholder style overwrite here
  // &.textarea {
  //   .mat-form-field-label {
  //     padding-left: $spacer8;
  //   }
  // }

  textarea {
    box-shadow: none;
    min-height: 100px;
    max-height: 200px;
    resize: none;
    box-sizing: border-box;
    padding: 10px;
    margin: 0;
  }
}

label.form-label {
  display: table;
  @include font(14px, 20px, 500, $text-tertiary);
  letter-spacing: normal;
  margin: 0 0 6px 0;
}
