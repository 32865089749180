@import '../includes';

/* Material Dialog Modal style overwrite here */
.mat-dialog-container {
  border-radius: 10px !important;

  .mat-dialog-title {
    @include font(18px, 28px, 700, $text-primary);
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }

  .close-icon {
    color: $text-primary;
    cursor: pointer;
  }

  .dialog-info {
    @include font(14px, 22px, 500, $text-tertiary);
  }

  .mat-dialog-actions {
    min-height: unset;
    margin-bottom: 0;
    padding: 24px 0 0 0;

    .mat-button-base+.mat-button-base {
      margin-left: 12px;
    }
  }
}

.support-contract-dialog .mat-dialog-container {
  .mat-dialog-content {
    max-height: calc(100vh - 100px);
  }
}

.view-action-dialog {
  .mat-dialog-content {
    overflow: auto;
  }
}

.change-admin-role {
  .mat-dialog-content {
    overflow: unset;
  }
}

.calendar-model {
  .icon-container {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: $primary-hint-4;
    display: flex;
    justify-content: center;
    align-items: center;

    .warn-icon {
      color: $primary-color !important;
      font-size: 26px;
    }
  }
}
