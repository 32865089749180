@import '../tools/custom';
@import '../settings/variables';

/* Material Button-toggle style overwrite here */
.dashboard-button-toggle {
  .mat-button-toggle-group {
    border-radius: $border-radius-rounded;
    .mat-button-toggle {
      .mat-button-toggle-label-content {
        line-height: 32px !important;
        font-size: 14px;
        padding: 0 $spacer20;
      }
    }
    .mat-button-toggle-checked {
      background-color: $primary-color;
      color: $white-primary;
    }
  }
}

.project-changes-custom-dialog {
  .mat-dialog-container {
    max-height: 600px;
    overflow: hidden !important;
  }
}
