@import './../tools/custom';
/* ==============================
   Border Radius Properties
---------------------------------*/

$border-radius-rounded: 50px;
$border-radius-half-rounded: 6px;
$border-radius-circle: 50%;

$global-border-radius: 2px;
$global-border-radius-1: 3px;
$border-radius: 5px;
$border-radius10: 10px;

/* ==============================
   Font Size Properties
---------------------------------*/

$font-size: 14px;

/* ==============================
   Box Shadow Properties
---------------------------------*/

$box-shadow1: 0 2px 15px 0 $grey-hint-10;
$box-shadow2: 0 2px 14px 0 $black-color-07;

/* ==============================
   Spacers Properties
---------------------------------*/

$spacer2: 2px;
$spacer4: 4px;
$spacer5: 5px;
$spacer6: 6px;
$spacer8: 8px;
$spacer10: 10px;
$spacer12: 12px;
$spacer14: 14px;
$spacer16: 16px;
$spacer20: 20px;
$spacer24: 24px;
$spacer32: 32px;
$spacer40: 40px;

/* ==============================
   Divider Properties
---------------------------------*/

$border-1: 1px solid $grey-lighten-2;
