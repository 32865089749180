@import '../includes';

/** Mat pagination styles here */
.mat-paginator {
  background: transparent;

  &-container {
    justify-content: flex-start !important;
    padding: 0 !important;
    min-height: unset !important;
  }

  .mat-paginator-page-size {
    margin-right: 0;

    &-label {
      @include font(13px, 20px, 500, $text-tertiary);
      margin: 0 6px;
    }
    &-select {
      margin: 0;
    }
  }

  .mat-paginator-range-label {
    @include font(13px, 20px, 500, $text-tertiary);
    margin: 0 12px;
  }

  button:hover {
    background: $background-primary;
  }

  .mat-icon-button {
    width: 36px;
    height: 36px;
    line-height: 36px;
    color: $text-primary;

    .mat-paginator-icon {
      width: 20px;
    }

    &:disabled {
      color: $text-light;
      background: none;
    }
  }

  mat-form-field.mat-form-field-type-mat-select .mat-select {
    padding: 6px;
  }
}
