@import '../includes';

/* Mat Snackbar style overwrite here */
.mat-snack-bar-container {
  box-shadow: 0px 13px 16px 0px $black-color-10;
  border-left: 5px solid;
  padding: 12px 16px !important;
  min-width: 405px !important;
  background: $white-primary;
  border-radius: 8px !important;

  .info-icon {
    display: inline-table;
    padding: 6px;
  }

  .close-icon {
   color: $text-hint;
  }

  p {
    @include font(16px, 22px, 500, $text-primary);
    letter-spacing: -0.408px;
  }

  &.success-snack-bar {
    border-color: $success-primary;

    .info-icon {
      color: $success-primary;
      background-color: $success-tertiary;
    }
  }

  &.error-snack-bar {
    border-color: $error-primary;

    .info-icon {
      color: $error-primary;
      background-color: $error-hint;
    }
  }

  &.warning-snack-bar {
    border-color: $warning-color-lighten;

    .info-icon {
      color: $warning-color-lighten;
      background-color: $warning-color-lighten-1;
    }
  }
}
