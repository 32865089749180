@import "../tools/custom";
@import "../settings/variables";

mat-progress-bar.mat-progress-bar {
    border-radius: 3.5px;
    height: $spacer8;

    &.progress-no-radius {
        border-radius: 0;
    }
    .mat-progress-bar-fill::after {
        background-color: $green;
        border-radius: 10px;
    }
    .mat-progress-bar-buffer {
        background-color: $grey-lighten-2;
    }

    &.mat-primary {
        .mat-progress-bar-fill::after {
            background-color: $primary-color;
            border-radius: 10px;
        }
    }
}
