@import '../tools/mixins';

.mat-drawer {
  &.medium {
    width: 500px;
  }
  &.extra-large {
    width: 600px;
  }
}

.drawer-header {
  .drawer-title {
    @include font(18px, 28px, 700, $text-primary);
    font-family: 'Plus Jakarta Sans', sans-serif !important;
  }
  .drawer-close-icon {
    color: $text-tertiary;
  }
}

.drawer-details {
  height: calc(100vh - 164px);
  overflow: auto;
}

.clear-filter-btn {
  @include positionTopRight(absolute !important, 20px, 64px);
  z-index: 1;
}
