@import '../tools/custom';
@import '../settings/variables';

.workspace-side-nav {
    width: 200px;
    .mat-expansion-panel {
        .mat-expansion-panel-header{
            padding: 8px 12px !important;
            height: fit-content !important;
            .mat-expansion-panel-header-title{
                margin-right: 0 !important;
            }
        }
        .mat-expansion-indicator::after{
            padding: 4px !important;
            position: relative;
            top: -4px;
        }
        .mat-expansion-panel-content {
            .mat-expansion-panel-body {
                padding: 0 4px 0 0 !important;
            }
        }
      }
      .mat-expansion-panel-header {
        height: 40px;

        &:hover {
            background-color: transparent !important;
        }
    }
  }
