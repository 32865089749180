@import '../tools/custom';
@import '../settings/variables';
@import '../tools/mixins';

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
  &:hover {
    background: $grey-lighten-3 !important;
  }
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px;
  background: $grey-lighten-3;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: $dark-green;
}
.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: $white-primary;
  border: solid 2px $grey-lighten-6;
  bottom: -20px;
  right: -20px;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: $white-primary;
}
.mat-slider-thumb-label {
  background: $dark-green !important;
}
.mat-slider-disabled .mat-slider-thumb {
  border-width: 4px;
  transform: scale(0.7);
}

/* score card NPS slider style here */
.score-card-nps {
  .mat-slider {
    width: 96%;
    .mat-slider-wrapper {
      .mat-slider-track-wrapper {
        height: 6px !important;
      }
      .mat-slider-thumb-container {
        .mat-slider-thumb {
          transform: rotate(45deg) !important;
          border-radius: $border-radius-circle !important;
          @include size(15px);
          bottom: -10px;
          right: -8px;
          background-color: $dark-green;
          border-color: $dark-green;
          box-shadow: 0 2px 4px $black-color-20;
          .mat-slider-thumb-label {
            display: none;
          }
        }
        .mat-slider-thumb-label {
          display: none;
        }
      }
    }
  }
}

.question-options {
  .mat-slider-wrapper {
    .mat-slider-track-wrapper {
      height: 8px !important;
      .mat-slider-track-background {
        background-color: $black-color-10;
        border: 1px solid $black-color-05;
      }
    }
    .mat-slider-thumb-container {
      .mat-slider-thumb-label {
        top: -12px !important;
        width: 30px;
        height: 30px;
        border-radius: 20% !important;
        background: $white-primary !important;
        border: 1px solid $dark-green !important;
        .mat-slider-thumb-label-text {
          color: $dark-green !important;
        }
      }
    }
  }
}
